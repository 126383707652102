import styled from 'styled-components';
import { colors } from '~/styles/ts/colors';
import { pxVw } from '~/styles/ts/functions';

export const Container = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: ${({ height }) => `${height}px`};
  height: 100%;
  padding-top: 80px;
  background: ${colors.snLightGray};
`;

export const Logo = styled.h1`
  position: relative;
  margin: 0 auto ${pxVw.SP(56)};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${pxVw.SP(56)};
  gap: ${pxVw.SP(32)};
`;
