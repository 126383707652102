export type SubTextSize = 'medium' | 'large';

interface SubTextData {
  ja: number;
  en: number;
}

type SubTextPreset = { fontSize: SubTextData; fontWeight: SubTextData };

type ButtonSubTextPreset = { [key in SubTextSize]: SubTextPreset };

export const BUTTON_SUB_TEXT_PRESET: ButtonSubTextPreset = {
  medium: {
    fontSize: { ja: 22, en: 26 },
    fontWeight: { ja: 500, en: 400 },
  },
  large: {
    fontSize: { ja: 24, en: 28 },
    fontWeight: { ja: 500, en: 400 },
  },
};
