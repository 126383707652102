export type ButtonSizes =
  | '128-48'
  | '400-48'
  | '120-50'
  | '154-64'
  | '108-68'
  | '116-72'
  | '356-72'
  | '160-80'
  | '224-80'
  | '520-80'
  | '480-96'
  | '208-112'
  | '280-112'
  | '320-112'
  | '400-112'
  | '450-112'
  | '480-112'
  | '520-112'
  | '280-144'
  | '400-144'
  | '480-144'
  | '520-144'
  | '640-144';

interface ButtonSizePresetValues {
  width: number;
  height: number;
  fontSize: { ja: number; en: number };
}

type ButtonSizePreset = { [key in ButtonSizes]: ButtonSizePresetValues };

export const BUTTON_SIZE_PRESET: ButtonSizePreset = {
  '128-48': { width: 128, height: 48, fontSize: { ja: 24, en: 28 } },
  '400-48': { width: 400, height: 48, fontSize: { ja: 20, en: 28 } },
  '120-50': { width: 120, height: 50, fontSize: { ja: 20, en: 20 } },
  '154-64': { width: 154, height: 64, fontSize: { ja: 24, en: 28 } },
  '108-68': { width: 108, height: 68, fontSize: { ja: 20, en: 24 } },
  '116-72': { width: 116, height: 72, fontSize: { ja: 24, en: 28 } },
  '356-72': { width: 356, height: 72, fontSize: { ja: 28, en: 28 } },
  '160-80': { width: 160, height: 80, fontSize: { ja: 24, en: 32 } },
  '224-80': { width: 224, height: 80, fontSize: { ja: 28, en: 32 } },
  '520-80': { width: 520, height: 80, fontSize: { ja: 32, en: 40 } },
  '208-112': { width: 208, height: 112, fontSize: { ja: 32, en: 40 } },
  '280-112': { width: 280, height: 112, fontSize: { ja: 32, en: 40 } },
  '320-112': { width: 320, height: 112, fontSize: { ja: 32, en: 40 } },
  '400-112': { width: 400, height: 112, fontSize: { ja: 32, en: 40 } },
  '450-112': { width: 450, height: 112, fontSize: { ja: 32, en: 40 } },
  '480-96': { width: 480, height: 96, fontSize: { ja: 32, en: 40 } },
  '480-112': { width: 480, height: 112, fontSize: { ja: 32, en: 40 } },
  '520-112': { width: 520, height: 112, fontSize: { ja: 32, en: 40 } },
  '280-144': { width: 280, height: 144, fontSize: { ja: 40, en: 50 } },
  '400-144': { width: 400, height: 144, fontSize: { ja: 36, en: 50 } },
  '480-144': { width: 480, height: 144, fontSize: { ja: 36, en: 50 } },
  '520-144': { width: 520, height: 144, fontSize: { ja: 36, en: 50 } },
  '640-144': { width: 640, height: 144, fontSize: { ja: 36, en: 50 } },
};
