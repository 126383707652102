import { useCallback } from 'react';
import type { URLString } from '~/interfaces/common';
import { entryPersistences } from '~/persistence';
import { sendGAEvent } from '~/utils/gtag';

export const useEntry = () => {
  const register = useCallback((url: URLString) => {
    if (entryPersistences.CampaignTag.value) {
      sendGAEvent({
        action: 'click',
        category: 'link',
        label: `signup_${entryPersistences.CampaignTag.value}`,
        value: 1,
      });
    }
    location.href = url;
  }, []);

  const login = useCallback((url: URLString) => {
    if (entryPersistences.CampaignTag.value) {
      sendGAEvent({
        action: 'click',
        category: 'link',
        label: `login_${entryPersistences.CampaignTag.value}`,
        value: 1,
      });
    }
    location.href = url;
  }, []);

  return {
    register,
    login,
  };
};
