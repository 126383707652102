import type { ComponentProps } from 'react';
import { useTheme } from 'styled-components';
import { BUTTON_SIZE_PRESET } from '../presets/buttonSizePreset';
import styles from './CommonBasicButton.module.scss';
import { CommonButtonWrapper } from '~/components/common/button/CommonButtonWrapper';
import { BUTTON_COLOR_PRESET } from '~/components/common/button/presets/buttonColorPreset';
import {
  BUTTON_IMAGE_PATH,
  BUTTON_IMAGE_PRESET,
  type BasicButtonImageSize,
  type BasicButtonImageType,
} from '~/components/common/button/presets/buttonImagePreset';
import {
  BUTTON_SUB_TEXT_PRESET,
  type SubTextSize,
} from '~/components/common/button/presets/buttonSubTextPreset';
import { CommonImage } from '~/components/common/image/CommonImage';
import { useTranslation } from '~/hooks/useTranslation';
import type { LocalizeKey } from '~/interfaces/i18n';
import { fontFamilies, pxVw } from '~/styles/ts/functions';
import { path } from '~/utils/path';

interface Props {
  text: LocalizeKey;
  size: ComponentProps<typeof CommonButtonWrapper>['size'];
  colorType: ComponentProps<typeof CommonButtonWrapper>['colorType'];
  borderWidth?: ComponentProps<typeof CommonButtonWrapper>['borderWidth'];
  onClick?: ComponentProps<typeof CommonButtonWrapper>['onClick'];
  disabled?: ComponentProps<typeof CommonButtonWrapper>['disabled'];
  imageFileName?: BasicButtonImageType;
  imageSize?: BasicButtonImageSize;
  subText?: LocalizeKey;
  subTextSize?: SubTextSize;
  isCheck?: boolean;
  className?: string;
}

export const CommonBasicButton = ({
  text,
  size,
  colorType,
  borderWidth = 4,
  onClick,
  disabled = false,
  imageFileName,
  imageSize,
  subText,
  subTextSize,
  isCheck,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { style, font } = useTheme();

  return (
    <CommonButtonWrapper
      size={size}
      colorType={colorType}
      borderWidth={borderWidth}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {isCheck ? (
        <div className={styles.container} data-is-column="false">
          <CommonImage src={path.image('/common/icon-check.svg')} width={64} height={53} />
        </div>
      ) : (
        <div className={styles.container} data-is-column={subText ? 'true' : 'false'}>
          {imageFileName && imageSize && (
            <div
              className={styles['image-wrap']}
              style={{
                width: pxVw.SP(BUTTON_IMAGE_PRESET[imageFileName][imageSize]!.width),
                height: pxVw.SP(BUTTON_IMAGE_PRESET[imageFileName][imageSize]!.height),
                marginRight: pxVw.SP(
                  BUTTON_IMAGE_PRESET[imageFileName][imageSize]!.marginRight[style],
                ),
              }}
            >
              <CommonImage
                src={path.image(BUTTON_IMAGE_PATH[imageFileName])}
                alt=""
                width={BUTTON_IMAGE_PRESET[imageFileName][imageSize]!.width}
                height={BUTTON_IMAGE_PRESET[imageFileName][imageSize]!.height}
                objectFit="contain"
              />
            </div>
          )}
          {subText && subTextSize && (
            <p
              className={styles['sub-text']}
              style={{
                fontFamily: fontFamilies[font.default],
                fontSize: pxVw.SP(BUTTON_SUB_TEXT_PRESET[subTextSize].fontSize[style]),
                fontWeight: BUTTON_SUB_TEXT_PRESET[subTextSize].fontWeight[style],
                color: BUTTON_COLOR_PRESET[colorType].color,
              }}
            >
              {t(subText)}
            </p>
          )}
          <p
            className={styles.text}
            style={{
              fontFamily: fontFamilies[font.default],
              fontSize: pxVw.SP(BUTTON_SIZE_PRESET[size].fontSize[style]),
              color: disabled
                ? BUTTON_COLOR_PRESET[colorType].disabledTextColor
                : BUTTON_COLOR_PRESET[colorType].color,
            }}
          >
            {t(text)}
          </p>
        </div>
      )}
    </CommonButtonWrapper>
  );
};
