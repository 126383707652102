import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import styled from 'styled-components';
import { CommonImage } from '~/components/common/image/CommonImage';
import { commonBasicTextCss } from '~/components/common/text/commonBasicTextCss';
import { useTranslation } from '~/hooks/useTranslation';
import { pxVw } from '~/styles/ts/functions';
import { sendGAEvent } from '~/utils/gtag';
import { path } from '~/utils/path';

type ScanButtonPageType = 'login' | 'measure';

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxVw.SP(640)};
`;

const Icon = styled.div`
  position: relative;
  display: block;
  width: ${pxVw.SP(80)};
  height: ${pxVw.SP(80)};
  margin-right: ${pxVw.SP(16)};
`;

const Texts = styled.div<{ page: ScanButtonPageType }>`
  em {
    font-style: normal;
    text-decoration: underline;
    ${({ theme }) =>
      commonBasicTextCss({
        size: theme.style === 'en' ? 24 : 20,
        lineHeight: theme.style === 'en' ? 1 : 1.4,
        weight: theme.style === 'en' ? 400 : 700,
        letterSpacing: theme.style === 'en' ? '0px' : '3%',
      })};
  }
`;

const LinkText = styled.p<{ page: ScanButtonPageType }>`
  ${({ theme }) =>
    commonBasicTextCss({
      size: theme.style === 'en' ? 40 : 36,
      lineHeight: 1.4,
      weight: 700,
      fontFamily: 'numeric',
    })};
`;

const SubText = styled.p<{ page: ScanButtonPageType }>`
  ${({ theme }) =>
    commonBasicTextCss({
      size: theme.style === 'en' ? 24 : 20,
      lineHeight: theme.style === 'en' ? 1 : 1.4,
      weight: theme.style === 'en' ? 400 : 700,
      letterSpacing: theme.style === 'en' ? '0px' : '3%',
      whiteSpace: 'nowrap',
    })};
`;

interface Props {
  className?: string;
  page: ScanButtonPageType;
}

export const ScanButton = ({ className, page }: Props) => {
  const { t } = useTranslation();
  const pathname = useRouter().pathname;

  const onClickMFID = useCallback(() => {
    sendGAEvent({
      action: 'AM3DFS',
      category: 'click',
      label: page,
    });
  }, []);

  const onClickFAQ = useCallback(() => {
    sendGAEvent({
      action: 'AM3DFS_faq',
      category: 'click',
      label: page,
    });
  }, []);

  return (
    <Button className={className}>
      <Link href={`/mfid?from=${pathname}`} passHref>
        <Icon onClick={onClickMFID}>
          <CommonImage src={path.image('/mfid/icon-scan-camera.svg')} width={81} height={80} />
        </Icon>
      </Link>
      <a href="/faq" target="_blank" onClick={onClickFAQ}>
        <Texts page={page}>
          <LinkText page={page}>ASICS MOBILE 3D FOOT SCAN</LinkText>
          <SubText page={page}>{t('scan_button_note')}</SubText>
        </Texts>
      </a>
    </Button>
  );
};
