import { useCallback, useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);

  const set = useCallback(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    set();
    window.addEventListener('load', set, false);
    window.addEventListener('resize', set, false);
    return () => {
      window.removeEventListener('load', set);
      window.removeEventListener('resize', set);
    };
  }, [set]);

  return {
    windowWidth,
    windowHeight,
  };
};
