import { colors } from '~/styles/ts/colors';

export type ButtonColorPresetKey =
  | 'positive'
  | 'negative'
  | 'alert'
  | 'noFill'
  | 'commonPositive'
  | 'commonNoFill'
  | 'grayBorder'
  | 'noFillDisabledOutline';

type ButtonColorPresetValue = {
  backgroundColor: string;
  color: string;
  borderColor: string;
  disabledColor: string;
  disabledBorderColor: string;
  disabledTextColor?: string;
};

type ButtonColorPreset = {
  [key in ButtonColorPresetKey]: ButtonColorPresetValue;
};

export const BUTTON_COLOR_PRESET: ButtonColorPreset = {
  commonPositive: {
    backgroundColor: colors.asicsKidsNavy,
    color: colors.white,
    borderColor: colors.transparent,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
  },
  commonNoFill: {
    backgroundColor: colors.transparent,
    color: colors.asicsKidsNavy,
    borderColor: colors.asicsKidsNavy,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
  },
  positive: {
    backgroundColor: colors.primary,
    color: colors.white,
    borderColor: colors.transparent,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
    disabledTextColor: colors.white,
  },
  negative: {
    backgroundColor: colors.bg.main,
    color: colors.primary,
    borderColor: colors.primary,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
  },
  alert: {
    backgroundColor: colors.snRed,
    color: colors.white,
    borderColor: colors.transparent,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
  },
  noFill: {
    backgroundColor: colors.transparent,
    color: colors.primary,
    borderColor: colors.primary,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
  },
  noFillDisabledOutline: {
    backgroundColor: colors.transparent,
    color: colors.primary,
    borderColor: colors.primary,
    disabledColor: colors.transparent,
    disabledTextColor: colors.gray_2,
    disabledBorderColor: colors.gray_2,
  },
  grayBorder: {
    backgroundColor: colors.transparent,
    color: colors.primary,
    borderColor: colors.gray_2,
    disabledColor: colors.gray_2,
    disabledBorderColor: colors.transparent,
  },
};
