import styled from 'styled-components';
import { CommonBasicButton } from '~/components/common/button/CommonBasicButton';
import { CommonImage } from '~/components/common/image/CommonImage';
import { Buttons, Container, Logo } from '~/components/entry/entry.components';
import { Footer } from '~/components/entry/Footer';
import { useEntry } from '~/components/entry/hooks/useEntry';
import { ScanButton } from '~/components/mfid/modules/ScanButton';
import { useWindowSize } from '~/hooks/useWindowSize';
import { pxVw } from '~/styles/ts/functions';
import { path } from '~/utils/path';
const ScanButtonArea = styled.div`
  margin: 0 auto ${pxVw.SP(80)};
`;

interface Props {
  RegisterUrl: string;
  LoginUrl: string;
}

export const EntryPresenter = ({ RegisterUrl, LoginUrl }: Props) => {
  const { windowHeight } = useWindowSize();
  const { register, login } = useEntry();

  return (
    <Container height={windowHeight}>
      <Logo>
        <CommonImage
          src={path.image('/entry/logo.svg')}
          width={528}
          height={240}
          objectFit="contain"
          alt="Stepnote"
        />
      </Logo>
      <Buttons>
        <CommonBasicButton
          text="login_btn_create"
          size="520-144"
          colorType="positive"
          subText="login_txt_create"
          subTextSize="medium"
          onClick={() => register(RegisterUrl)}
        />
        <CommonBasicButton
          text="login_btn_login"
          size="520-144"
          colorType="negative"
          subText="login_txt_login"
          subTextSize="medium"
          onClick={() => login(LoginUrl)}
        />
      </Buttons>
      <ScanButtonArea>
        <ScanButton page="login" />
      </ScanButtonArea>
      <Footer />
    </Container>
  );
};
