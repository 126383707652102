import { EntryContainer } from '~/components/entry/Index';
import { DefaultLayout } from '~/layouts/DefaultLayout';

const Entry = () => {
  return (
    <DefaultLayout>
      <EntryContainer />
    </DefaultLayout>
  );
};

export default Entry;
