type GTagMethod = {
  event: [
    string,
    {
      event_category?: string;
      event_label?: string;
      event_value?: number | string;
      content_type?: string;
      items?: CommonImpressionFieldObject[];
    },
  ];
};

declare global {
  interface Window {
    gtag: <K extends keyof GTagMethod>(
      type: K,
      payload1: GTagMethod[K][0],
      payload2: GTagMethod[K][1],
    ) => void;
  }
}

type SendEventProps = {
  action: string;
  category: string;
  label?: string;
  value?: number | string;
};

export function sendGAEvent({ action, category, label, value }: SendEventProps) {
  try {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        event_value: value,
      });
    }
  } catch (error) {
    return error;
  }
}

export interface CommonImpressionFieldObject {
  item_id: string;
  item_name: string;
  affiliation: 'stepnote';
  currency?: string;
  index: number; // 整数値
  item_brand: string;
  item_category: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  item_size: number;
  price: number; // 整数値
  quantity: number;
}

// eslint-disable-next-line import/no-unused-modules
export type SendImpressionType =
  | 'view_item_list'
  | 'view_item_list_home'
  | 'view_item_list_timeline'
  | 'view_item_list_measure'
  | 'view_item_list_event'
  | 'view_item_list_graph'
  | 'view_item_list_growth';

type CommonSendImpressionProps = {
  type?: SendImpressionType;
  items: CommonImpressionFieldObject[];
};

export const commonSendGAImpression = ({
  type = 'view_item_list',
  items,
}: CommonSendImpressionProps) => {
  const payload = {
    ecommerce: {
      item_list_id: items[0].item_list_id,
      item_list_name: items[0].item_list_name,
      items,
    },
  };
  try {
    if (window.gtag) {
      window.gtag('event', type, payload);
    }
  } catch (error) {
    return error;
  }
};

type CommonSendProductClickProps = {
  item: CommonImpressionFieldObject;
};

export const sendGAProductClick = ({ item }: CommonSendProductClickProps) => {
  const payload = {
    ecommerce: {
      item_list_id: item.item_list_id,
      item_list_name: item.item_list_name,
      items: [item],
    },
  };
  try {
    if (window.gtag) {
      window.gtag('event', 'select_item', payload);
    }
  } catch (error) {
    return error;
  }
};
