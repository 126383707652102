export type BasicButtonImageType =
  | 'measure'
  | 'graph'
  | 'knowledge'
  | 'cart'
  | 'save'
  | 'edit'
  | 'delete';

export type BasicButtonImageSize = 'small' | 'middle' | 'middleEn16' | 'large';

interface ImageData {
  width: number;
  height: number;
  marginRight: { ja: number; en: number };
}

type ImageSizeData = { [key in BasicButtonImageSize]?: ImageData };

type ButtonImagePreset = { [key in BasicButtonImageType]: ImageSizeData };

type ButtonImagePath = { [key in BasicButtonImageType]: string };

export const BUTTON_IMAGE_PRESET: ButtonImagePreset = {
  measure: {
    small: { width: 24, height: 40, marginRight: { ja: 24, en: 8 } },
    middleEn16: { width: 34, height: 56, marginRight: { ja: 24, en: 16 } },
    middle: { width: 34, height: 56, marginRight: { ja: 24, en: 24 } },
  },
  graph: {
    large: { width: 64, height: 54, marginRight: { ja: 32, en: 32 } },
  },
  knowledge: {
    large: { width: 45, height: 72, marginRight: { ja: 24, en: 20 } },
  },
  cart: {
    large: { width: 48, height: 49, marginRight: { ja: 16, en: 16 } },
  },
  save: {
    large: { width: 48, height: 48, marginRight: { ja: 16, en: 16 } },
  },
  edit: {
    large: { width: 32, height: 32, marginRight: { ja: 0, en: 0 } },
  },
  delete: {
    large: { width: 32, height: 32, marginRight: { ja: 0, en: 0 } },
  },
};

export const BUTTON_IMAGE_PATH: ButtonImagePath = {
  measure: '/gnav/icon_ashi.svg',
  graph: '/record/button-icon-graph.svg',
  knowledge: '/record/button-icon-knowledge.svg',
  cart: '/home/button-icon-cart.svg',
  save: '/cloakroom/button-icon-save.svg',
  edit: '/cloakroom/button-icon-edit.svg',
  delete: '/cloakroom/button-icon-delete.svg',
};
