import router from 'next/router';
import { useEffect } from 'react';
import { EntryPresenter } from '~/components/entry/Presenter';
import { authConstants } from '~/masterdata/auth';
import { entryPersistences } from '~/persistence';
import { sendGAEvent } from '~/utils/gtag';

export const EntryContainer = () => {
  const { utm_campaign, utm_source, utm_medium } = router.query;

  const RegisterUrl = [
    authConstants.BASE_URL,
    authConstants.REGISTER_PATH,
    authConstants.REGISTER_QUERY_STRINGS,
  ].join('');
  const LoginUrl = [
    authConstants.BASE_URL,
    authConstants.LOGIN_PATH,
    authConstants.LOGIN_QUERY_STRINGS,
  ].join('');

  useEffect(() => {
    if (!utm_campaign || !utm_source || !utm_medium || entryPersistences.CampaignTag.value) return;

    const tag = `${utm_campaign}_${utm_source}_${utm_medium}`;
    entryPersistences.CampaignTag.set(tag);
    entryPersistences.Campaigns.set({
      utm_campaign: utm_campaign.toString(),
      utm_source: utm_source.toString(),
      utm_medium: utm_medium.toString(),
    });
    sendGAEvent({
      action: 'pageView',
      category: 'page',
      label: `top_${tag}`,
      value: 1,
    });
  }, [utm_campaign, utm_source, utm_medium]);

  return <EntryPresenter RegisterUrl={RegisterUrl} LoginUrl={LoginUrl} />;
};
