import { useRef, useCallback } from 'react';
import type { MouseEventHandler, ReactNode } from 'react';
import styles from './CommonButtonWrapper.module.scss';
import type { ButtonColorPresetKey } from '~/components/common/button/presets/buttonColorPreset';
import { BUTTON_COLOR_PRESET } from '~/components/common/button/presets/buttonColorPreset';
import type { ButtonSizes } from '~/components/common/button/presets/buttonSizePreset';
import { BUTTON_SIZE_PRESET } from '~/components/common/button/presets/buttonSizePreset';
import { pxVw } from '~/styles/ts/functions';

interface Props {
  size: ButtonSizes;
  colorType: ButtonColorPresetKey;
  borderWidth: number;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

export const CommonButtonWrapper = ({
  size,
  colorType,
  borderWidth,
  onClick,
  disabled = false,
  className = '',
  children,
}: Props) => {
  const onClickTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (!onClick) return;
    if (onClickTimerRef.current) clearTimeout(onClickTimerRef.current);
    onClickTimerRef.current = setTimeout(() => {
      onClick();
    }, 500);
  }, [onClick]);

  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={handleOnClick}
      disabled={disabled}
      style={{
        borderRadius: pxVw.SP(BUTTON_SIZE_PRESET[size].height / 2),
        borderWidth: pxVw.SP(borderWidth),
        width: pxVw.SP(BUTTON_SIZE_PRESET[size].width),
        height: pxVw.SP(BUTTON_SIZE_PRESET[size].height),
        ...(disabled
          ? {
              backgroundColor: BUTTON_COLOR_PRESET[colorType].disabledColor,
              borderColor: BUTTON_COLOR_PRESET[colorType].disabledBorderColor,
            }
          : {
              backgroundColor: BUTTON_COLOR_PRESET[colorType].backgroundColor,
              borderColor: BUTTON_COLOR_PRESET[colorType].borderColor,
            }),
      }}
    >
      {children}
    </button>
  );
};
