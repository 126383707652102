const getLocaleFromPath = () => {
  if (typeof window === 'undefined') return;
  const path = window.location.pathname;
  const regionPath = path.split('/').filter((part) => part)[0];
  if (regionPath === 'jp') return 'ja-JP' ?? 'en-US';
};

const queryObject = {
  client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID ?? '',
  locale: getLocaleFromPath() ?? 'en-US',
  grant_type: 'code',
  style: 'stepnote',
  redirect_uri: encodeURIComponent(process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI ?? ''),
  privacy_url: encodeURIComponent('https://stepnote.asics.com/policy.html'),
  terms_url: encodeURIComponent('https://stepnote.asics.com/term.html'),
  oneasics_terms_url: encodeURIComponent(
    'https://www.asics.com/us/en-us/mk/oneasics-terms-and-conditions',
  ),
  region: 'Global',
  cookie_url: encodeURIComponent('https://www.asics.com/us/en-us/cookie-policy.html!new'),
};

const registerQueryObject = {
  ...queryObject,
  no_confirm_email: 'true',
  send_confirm_email: 'false',
};

const loginQueryObject = {
  ...queryObject,
  no_confirm_email: 'true',
  send_confirm_email: 'false',
};

const logQ = new URLSearchParams(loginQueryObject);
const regQ = new URLSearchParams(registerQueryObject);

export const authConstants = {
  BASE_URL: process.env.NEXT_PUBLIC_AUTH_URL,
  REGISTER_PATH: '/lander',
  LOGIN_PATH: '/login',
  REGISTER_QUERY_STRINGS: '?' + regQ.toString(),
  LOGIN_QUERY_STRINGS: '?' + logQ.toString(),
};
