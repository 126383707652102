import styled from 'styled-components';
import { CommonImage } from '~/components/common/image/CommonImage';
import { colors } from '~/styles/ts/colors';
import { fontFamilies, pxVw } from '~/styles/ts/functions';
import { path } from '~/utils/path';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LogoWrap = styled.div`
  position: relative;
  margin-bottom: ${pxVw.SP(32)};
`;

const Rights = styled.small`
  font-family: ${fontFamilies.sen};
  font-weight: 400;
  color: ${colors.text.main};
  text-align: center;
  font-size: ${pxVw.SP(20)};
  line-height: 1;
`;

export const Footer = () => {
  return (
    <Container>
      <LogoWrap>
        <CommonImage src={path.image('/common/icon-logo.svg')} width={240} height={72} />
      </LogoWrap>
      <Rights>&copy; 2024 ASICS Corporation. All Rights Reserved.</Rights>
    </Container>
  );
};
